import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`At `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we recently surpassed 7 billion messages processed.`}</p>
    <p>{`As we mentioned before, it’s important to look at the messages users are sending to your bots. The beauty of conversational interfaces is that users say exactly what they want from the bot, and what they think of it afterwards. Examining the messages and taking action on them can help improve the engagement of your bot.`}</p>
    <p>{`In the past, we looked at top messages across all Facebook bots.`}</p>
    <p>{`However, bots are developed and used by people all over the world. Users can come from anywhere and say anything, including in their native language.`}</p>
    <p>{`We took a closer look at messages based on language.`}</p>
    <p>{`We looked at the most common languages in Facebook bots based on the percentage of bots having users with the particular language setting. The following are the top languages.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115454/1454a-1c-oddq-pu9llee4ool_fpg.png)
    </figure>
English is by far the most common language — about 90% of bots have English
users. That being said, looking across all bots, on average about 68% of a bot’s
users are English speakers. The percentage of users based on language depends on
the bot as some bots are targeting a particular language or region.
    <p>{`We looked at the most common messages overall, as well as based on language. As has been the case for a while, “hi” is the most common message sent to Facebook bots overall. If you’re building a bot, adding support for these common messages can help increase engagement.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115453/0_VV_6KtnDfOtrmynE.png)
    </figure>
Where it gets more interesting, is looking at the most common messages based on
the users’ language. Interestingly enough, English words are often still the
most common messages, regardless of language.
    <p>{`When looking by language, we see the native variations of “hi” and “hello” are popular too. In French, bonjour and salut are hello. In Spanish, hola is hello. In Portuguese, oi is hi, olá is hello, and “bom dia” is good morning. In Italian, ciao is both hello and goodbye. In Arabic, مرحبا and سلام are both hello. In Russian, привет is hi. In Chinese, 你好 is “hello there.” In Dutch, hoi is hi.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115453/0_4Q96BVMhiUUTnVWf.png)
    </figure>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115452/0_jIbGszECPnfSn1xH.png)
    </figure>We also see some of the other common English words in the native language
— like شكرا is “thank” in Arabic and 謝謝 is “thank you” in Chinese. Si is yes in
Spanish and Italian, and sim is yes in Portuguese. Merci and gracias are “thank you”
in French and Spanish respectively.
    <p>{`Users communicate with more than just words.`}</p>
    <p>{`Stickers are an additional means to communicate feelings and emotions. They are quite popular around the world amongst different languages.`}</p>
    <p>{`The most common stickers across all Facebook bots the past two months are below.`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115451/0_OedN6SiX4xQ9fD9G-339x1024.png)
    </figure>
We also looked at stickers used across different languages.
    <p>{`Love is a common theme regardless of language. It’s even more pronounced in Italian — five of the top ten stickers are love related. Looking back at the common messages overall, the heart character is also pretty popular in Italian — apparently Italians love to love!`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/08/07115450/0_3AicdKuDXnB0ZbCD.png)
    </figure>
If you’re building a bot it’s important to look at the metrics including the
individual messages users are sending. It’s information you can use to provide
more effective responses to users, and in the way users like to communicate.
    <p>{`At `}<a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{`, we provide tools like our message funnel to help developers better understand users, their interests, and communication behaviors.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`In addition to traditional analytics like engagement and retention, we provide chatbot specific metrics including sentiment analysis, conversational analytics, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our live person take over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We natively support Facebook Messenger, Alexa, Google Home, Slack, Twitter, and Kik and have a generic API for any conversational interface.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      